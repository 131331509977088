import css from 'styled-jsx/css';

const styles = css`
  .profile-avatar-vip-frame {
    position: absolute;
    top: 0;
    &.lg {
      :global(svg) {
        height: 64px;
        width: 64px;
      }
    }
    &.md {
      :global(svg) {
        height: 40px;
        width: 40px;
      }
    }
    &.sm {
      :global(svg) {
        height: 32px;
        width: 32px;
      }
    }
  }
`;

export default styles;
