import COLORS from '@utils/colors';
import {
  BREAKPOINT,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE,
} from '@utils/constants';
import css from 'styled-jsx/css';

const { desktop, desktopL, mobile } = BREAKPOINT;

const styles = css`
  .header {
    z-index: 110;
    position: fixed;
    top: -${HEADER_HEIGHT_DESKTOP}px;
    transform: translateY(${HEADER_HEIGHT_DESKTOP}px);
    left: 0;
    width: 100%;
    box-shadow:
      0px 2px 4px -2px rgba(24, 39, 75, 0.08),
      0px 4px 4px -2px rgba(24, 39, 75, 0.04);
    background-color: ${COLORS.white};
    @media screen and (max-width: ${desktopL}px) {
      // Needs to be under PropertySearchModal for except Desktop
      z-index: 10;
    }
    @media screen and (max-width: ${desktop}px) {
      top: -${HEADER_HEIGHT_MOBILE}px;
    }
    @media screen and (max-width: ${mobile}px) {
      transform: translateY(${HEADER_HEIGHT_MOBILE}px);
    }

    .header__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1280px;
      margin: 0 auto;
      padding: 12px 16px;
      height: ${HEADER_HEIGHT_DESKTOP}px;
      @media screen and (max-width: ${desktop}px) {
        padding: 8px 16px;
        height: ${HEADER_HEIGHT_MOBILE}px;
      }
      position: relative;

      .header--only-logo {
        justify-content: center;
        padding: 12px;
      }
    }

    &__menu {
      margin-left: 16px;
      @media screen and (max-width: ${desktop}px) {
        margin-left: 8px;
      }
    }
    .header-coin-cont {
      margin: 0 12px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    .header__icons {
      display: flex;
      align-items: center;
    }
  }

  :global(.link) {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: normal;
    gap: 2px;
    padding: 8px 10px 8px 8px;
    border-radius: 20px;

    @media screen and (hover: hover) {
      &:hover {
        background-color: ${COLORS.black200};
      }
    }
  }

  :global(.link__icon) {
    position: relative;
    :global(.claim-coins) {
      position: absolute;
      top: 26px;
      animation: floating-y 0.8s ease-in-out infinite alternate-reverse;
      background-color: ${COLORS.pink500};
      width: max-content;
      height: 24px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      &:before {
        content: '';
        position: absolute;
        top: -16px;
        left: 58%;
        margin-left: -15px;
        border: 8px solid transparent;
        border-bottom: 10px solid ${COLORS.pink500};
        z-index: 2;
      }
    }
  }
  @keyframes floating-y {
    0% {
      transform: translateY(-5%);
    }
    100% {
      transform: translateY(5%);
    }
  }

  :global(.link__label) {
    font-size: 16px;
    font-weight: bold;
    color: ${COLORS.black900};
  }

  .buttons {
    display: flex;

    .buttons__button {
      &:not(:first-of-type) {
        margin-left: 8px;
      }
    }
  }

  .subscribe-button {
    padding-right: 0.25rem;

    @media screen and (min-width: ${desktop}px) {
      padding-left: 1rem;
      padding-right: 0.5rem;
    }
  }

  .coin-nav-promotion-label-cont {
    position: relative;
    z-index: 3;

    .coin-on-sale {
      background-color: ${COLORS.purple100};
      width: 80px;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: -6px;
      display: flex;
      border-radius: 7px;
      :global(.typo) {
        display: inline-block;
        white-space: nowrap;
      }
      :global(.typo:nth-child(odd)) {
        animation: loop1 10s linear -25s infinite;
      }
      :global(.typo:nth-child(even)) {
        animation: loop2 10s linear infinite;
      }
      @keyframes loop1 {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      @keyframes loop2 {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-200%);
        }
      }
    }

    .vip-coin-bonus {
      padding: 0px 6px;
      margin-bottom: -10px;
      border-radius: 18px;
      background: ${COLORS.purple100};
    }

    .flight-prize-campaign {
      padding: 0px 6px;
      margin-bottom: -10px;
      border-radius: 18px;
      background: #ebe5f8;
      :global(span) {
        color: #7149ce;
        white-space: nowrap;
      }
    }
  }
`;

export default styles;
