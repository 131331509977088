import React from 'react';
import IconHeartLine from '@assets/icon-heart-line.svg';
import IconInbox from '@assets/icon-inbox.svg';
import IconCalendar from '@assets/new/icon-calendar.svg';
import IconFlight from '@assets/new/icon-flight.svg';
import IconProperty from '@assets/new/icon-property.svg';
import UnreadBadge from '@atoms/UnreadBadge';
import { Desktop, Mobile } from '@components/Layout';
import Link from '@components/Link';
import { useAppSelector } from '@hooks/useAppSelector';
import { useIsJalCampaign202409Active } from '@hooks/useCampaign';
import IconWithText from '@molecules/IconWithText';
import { useIsJapanUser } from '@utils/hooks';
import { getNeighborSubscribed } from '@utils/neighbor';
import routes from '@utils/routes';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type IconNavProps = {
  Icon: React.ComponentType<{ className?: string }>;
  className?: string;
  href: string;
  isActive: boolean;
  text: string;
};

const IconNav = ({ Icon, className, href, isActive, text }: IconNavProps) => (
  <Link href={href}>
    <IconWithText
      Icon={Icon}
      className={className}
      isActive={isActive}
      text={text}
    />
  </Link>
);

const NavIconList: React.FC = () => {
  const { locale, pathname } = useRouter();
  const { t } = useTranslation('nav-icons');

  const { neighbor, unreadCount } = useAppSelector((state) => ({
    neighbor: state.neighbor,
    unreadCount: state.unreadCount,
  }));

  const isJapanUser = useIsJapanUser();
  const showBookingNav = getNeighborSubscribed(neighbor);
  const isJalCampaign202409Active = useIsJalCampaign202409Active();

  return (
    <div className="icons">
      <IconNav
        Icon={IconProperty}
        href={routes.browse}
        isActive={pathname === routes.properties || pathname === routes.browse}
        text={t('search')}
      />
      {isJapanUser && (
        <div className="flight-nav">
          <IconNav
            Icon={IconFlight}
            href={routes.v2FlightReservationsNew}
            isActive={pathname.includes(routes.v2FlightReservationsNew)}
            text={t('flightSearch')}
          />
          {isJalCampaign202409Active && (
            <>
              <Desktop>
                <Image
                  alt="50% coin back!"
                  className="campaign-badge-desktop"
                  height={29}
                  src={`/images/campaign/jal-campaign-202409/badge-desktop-${
                    locale === 'ja' ? 'ja' : 'en'
                  }.png`}
                  width={91}
                />
              </Desktop>
              <Mobile>
                <Image
                  alt="50% coin back!"
                  className="campaign-badge-mobile"
                  height={29}
                  src={`/images/campaign/jal-campaign-202409/badge-mobile-${
                    locale === 'ja' ? 'ja' : 'en'
                  }.png`}
                  width={91}
                />
              </Mobile>
            </>
          )}
        </div>
      )}
      <IconNav
        Icon={IconHeartLine}
        className="favorite"
        href={routes.favoriteProperties}
        isActive={pathname === routes.favoriteProperties}
        text={t('favorites')}
      />
      {showBookingNav && (
        <IconNav
          Icon={IconCalendar}
          href={routes.reservations}
          isActive={
            pathname === routes.reservations || pathname === routes.reservation
          }
          text={t('bookingHistory')}
        />
      )}
      <div className="icons-inbox test-header-inbox">
        <IconNav
          Icon={IconInbox}
          className="inbox"
          href={routes.inbox}
          isActive={
            pathname === routes.inbox || pathname === routes.inboxDetail
          }
          text={t('inbox')}
        />
        <UnreadBadge count={unreadCount?.total} />
      </div>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default NavIconList;
