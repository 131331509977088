import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .avatar {
    display: flex;
    align-items: center;
    overflow: hidden;
    :global(img) {
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .no-image {
    background: ${COLORS.black300};
    border-radius: 50%;
  }

  .no-image.avatar-sm {
    height: 32px;
    padding: 0 6px;
  }
  .no-image.avatar-md {
    height: 40px;
    padding: 0 8px;
  }
  .no-image.avatar-lg {
    height: 64px;
    padding: 0 8px;
  }
  .no-image.avatar-xl {
    height: 96px;
    padding: 0 16px;
  }

  .avatar-sm {
    min-width: 32px;
  }
  .avatar-md {
    min-width: 40px;
  }
  .avatar-lg {
    min-width: 64px;
  }
  .avatar-xl {
    min-width: 96px;
  }
`;

export default styles;
